<template>
<div class="community">
    <div class="tab-search-box">
      <img  src="~assets/images/index/t-search.png" class="tab-search" @click="goSearch()"/>
    </div>

     <div class="reward-list-top" v-show="active == '0'">
                    <div class="reward-list-top-l">{{category_name_show}}</div>
                    <div @click="showPopup" class="reward-list-top-r vertical-center"> 全部<img  src="~assets/images/index/setup.png"  class="reward-list-top-r-img"/>
                    </div>
                </div>
      <div class="market-list-top" v-show="active == '1'">
                    <div class="market-list-top-l">{{fleacategory_name_show}}</div>
                    <div @click="fshowPopup" class="market-list-top-r  vertical-center">
                        全部
                        <img  src="~assets/images/index/setup.png" class="market-list-top-r-img"/>
                    </div>
                </div>
  <div class="reward-list-top" v-show="active == '2'">
    <div class="reward-list-top-l">{{elecategory_name_show}}</div>
    <div @click="eshowPopup" class="reward-list-top-r vertical-center"> 全部<img  src="~assets/images/index/setup.png"  class="reward-list-top-r-img"/>
    </div>
  </div>
    <van-tabs v-model:active="active" ref="tabs" sticky offset-top="1.365rem"  @click="onChange" border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs" swipeable>
       

        <van-tab title="跳蚤市场" name="1">
            <div class="market-list">
              <NoData v-if="fleaList.length == 0" :bottomBg="true"></NoData>
              
                <div class="market-list-items" >
                      <van-list
        v-model:loading="flealoading"
        :finished="fleafinished"
        :finished-text="fleaList.length > 5 ? '没有更多了':''"
        @load="fleaonLoad"
        >
                    <flea :fleaData="fleaList" type='lb'></flea>
                     </van-list>
                </div>


            </div>
        </van-tab>
      <van-tab title="悬赏问答" name="0">
        <div class="reward-list">

          <van-list
            v-model:loading="bountyloading"
            :finished="bountyfinished"
            :finished-text="communityList.length > 5 ? '没有更多了':''"
            @load="bountyonLoad"
          >
            <bounty :bountyData="communityList" type="lb"></bounty>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="资源互助" name="2">
        <div class="reward-list">

          <van-list
            v-model:loading="eleloading"
            :finished="elefinished"
            :finished-text="eleList.length > 5 ? '没有更多了':''"
            @load="eleonLoad"
          >
            <resources :electronData="eleList" type="lb"></resources>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>

    <img  src="~assets/images/index/release.png"  class="tab-release" @click="create"/>
    <van-popup v-model:show="show" position="right" :style="{ height: '100%' }"  closeable>
        <div class="popup">
            <div class="popup-item">
                <div class="popup-item-top">悬赏积分</div>
                <div class="popup-item-btns">
                    <div :class="credit_low == 0 && credit_high == 10 ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeCredit(0,10)">0-10</div>
                    <div :class="credit_low == 10 ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeCredit(10,20)">10-20</div>
                    <div :class="credit_low == 20 ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeCredit(20,'')">20以上</div>
                    <div :class="credit_low == '' && credit_high == '' ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeCredit('','')">全部</div>
                </div>
            </div>
            <div class="popup-item">
                <div class="popup-item-top">是否已解决</div>
                <div class="popup-item-btns">
                    <div :class="is_solve == 'all' ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeSolve('all')" >全部</div>
                    <div :class="is_solve == 1 ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeSolve('1')" >已解决</div>
                    <div :class="is_solve == 0 ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeSolve('0')" >未解决</div>
                </div>
            </div>
            <div class="popup-item">
                <div class="popup-item-top">排序</div>
                <div class="popup-item-btns">
                    <div :class="sort_type == 'hot' ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeSorttype('hot')">按热度</div>
                    <div :class="sort_type == 'time' ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeSorttype('time')">按时间</div>
                </div>
            </div>
            <div class="popup-item">
                <div class="popup-item-top">帖子分类</div>
                <div class="popup-item-btns">
                    <div :class="category_id == 0 ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeCategory('0','全部')">全部</div>
                    <div :class="category_id == item.id ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeCategory(item.id,item.name)" v-for="(item,index) in category"  :key="index" >{{item.name}}</div>
                </div>
            </div>
            <div class="popup-footer safety-height">
                <div class="popup-footer-l" @click="reset()">重置</div>
                <div class="popup-footer-r" @click="confirm()">确定</div>
            </div>
        </div>
    </van-popup>
    <van-popup v-model:show="fleashow" position="right" :style="{ height: '100%' }" closeable>
        <div class="popup">
            <div class="popup-item">
                <div class="popup-item-top">悬赏积分</div>
                <div class="popup-item-btns">
                    <div :class="fcredit_low == 0  && fcredit_high == 10? 'popup-item-btn active' : 'popup-item-btn' " @click="changefleaCredit('0','10')" >0-10</div>
                    <div :class="fcredit_low == 10 ? 'popup-item-btn active' : 'popup-item-btn' " @click="changefleaCredit('10','20')" >10-20</div>
                    <div :class="fcredit_low == 20 ? 'popup-item-btn active' : 'popup-item-btn' " @click="changefleaCredit('20','')" >20以上</div>
                    <div :class="fcredit_low == '' && fcredit_high == '' ? 'popup-item-btn active' : 'popup-item-btn' " @click="changefleaCredit('','')" >全部</div>
                </div>
            </div>
            <div class="popup-item">
                <div class="popup-item-top">是否包邮</div>
                <div class="popup-item-btns">
                    <div :class="is_free_shipping == 'all' ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeshipping('all')" >全部</div>
                    <div :class="is_free_shipping == 1 ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeshipping('1')" >包邮</div>
                    <div :class="is_free_shipping == 0 ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeshipping('0')" >不包邮</div>
                </div>
            </div>
            <div class="popup-item">
                <div class="popup-item-top">商品分类</div>
                <div class="popup-item-btns">
                    <div :class="fleaCategory_id == 0 ? 'popup-item-btn active' : 'popup-item-btn' " data-id="0" data-name="全部" @click="changefleaCategory('0','全部')">全部</div>
                    <div :class="fleaCategory_id == item.id ? 'popup-item-btn active' : 'popup-item-btn' " @click="changefleaCategory(item.id,item.name)" v-for="(item,index) in fleacategory"  :key="index" >{{item.name}}</div>
                </div>
            </div>
            <div class="popup-footer">
                <div class="popup-footer-l" @click="freset()">重置</div>
                <div class="popup-footer-r" @click="fconfirm()">确定</div>
            </div>
        </div>
    </van-popup>
  <van-popup v-model:show="eleshow" position="right" :style="{ height: '100%' }"  closeable>
    <div class="popup">
      <div class="popup-item">
        <div class="popup-item-top">资源积分</div>
        <div class="popup-item-btns">
          <div :class="ecredit_low == 0 && ecredit_high == 10 ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeeleCredit(0,10)">0-10</div>
          <div :class="ecredit_low == 10 ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeeleCredit(10,20)">10-20</div>
          <div :class="ecredit_low == 20 ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeeleCredit(20,'')">20以上</div>
          <div :class="ecredit_low == '' && ecredit_high == '' ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeeleCredit('','')">全部</div>
        </div>
      </div>
      <div class="popup-item">
        <div class="popup-item-top">资源分类</div>
        <div class="popup-item-btns">
          <div :class="elecategory_id == '' ? 'popup-item-btn active' : 'popup-item-btn' " @click="changeeleCategory('','全部')">全部</div>
          <div :class="elecategory_id == 'book' ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeeleCategory('book','电子书')">电子书</div>
          <div :class="elecategory_id == 'package' ? 'popup-item-btn active' : 'popup-item-btn'" @click="changeeleCategory('package','软件包')">软件包</div>
        </div>
      </div>
      <div class="popup-footer safety-height">
        <div class="popup-footer-l" @click="ereset()">重置</div>
        <div class="popup-footer-r" @click="econfirm()">确定</div>
      </div>
    </div>
  </van-popup>
  <van-toast id="van-toast" />
</div>
</template>

<script>
import UserInfo from "components/userInfo";
import bounty from "../../../components/bounty"
import flea from "../../../components/flea"
import resources from "../../../components/resources"
import NoData from "components/NoData";
export default {
  name: "sign",
  data(){
    return{
        active: '1',
        show:false,
        communityList:[],
        page:1,
        load:true,
        is_solve:'all',
        category_id:0,
        category:[],
        fleacategory:[],
        sort_type:'time',
        credit_low:'',
        credit_high:'',
        fleaList:[],
        fleapage:1,
        is_free_shipping:'all',
        fleaCategory_id:0,
        fleaLoad:true,
        fleashow:false,
        fcredit_low:'',
        fcredit_high:'',
        category_name:'全部',
        category_name_show:'全部',
        fleacategory_name:'全部',
        fleacategory_name_show:'全部',
        bountyloading:false,
        bountyfinished:true,
        flealoading:false,
        fleafinished:true,
        eleList:[],
        elepage:1,
      ecredit_low:'',
      ecredit_high:'',
      type:'',
      eleLoad:true,
      eleshow:false,
      elecategory_id:'',
      elecategory_name:'全部',
      elecategory_name_show:'全部',
      eleloading:false,
      elefinished:true,

    }
  },
    created() {
        this.active = this.$store.getters.act
         this.$store.commit('M_ACT','1')
          this.$store.commit('M_ACTS','1')
      this.getcommunityList()
      this.getfleaList()
      this.geteleList()
      this.CommunityCategory()
      this.$nextTick(() => {
        console.log(this.$refs.tabs)
        this.$refs.tabs.resize()
      })
  },
  methods:{
    //电子资源列表
    geteleList(){

      let url = this.$api.electron + '?pageSize=6&page=' + this.elepage   + '&credit_low=' + this.ecredit_low + '&credit_high=' + this.ecredit_high + '&type=' + this.elecategory_id
      this.$http.get(url, false).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            this.eleList = this.eleList.concat(res.data.data),
              this.elepage = this.elepage + 1
            this.elefinished = false
            this.eleloading = false;
          }else{
            this.elefinished = true
            this.eleLoad=false
          }
        }
      })
    },
     //跳蚤市场列表
    getfleaList(){
      let url = this.$api.Flea + '?pageSize=6&page=' + this.fleapage + '&is_free_shipping=' + this.is_free_shipping +'&category_id=' + this.fleaCategory_id  + '&credit_low=' + this.fcredit_low + '&credit_high=' + this.fcredit_high
      this.$http.get(url, false).then(res => {
        if(res.data.success){
          if(res.data.data.length){
              this.fleaList = this.fleaList.concat(res.data.data),
              this.fleapage = this.fleapage + 1
              this.fleafinished = false
              this.flealoading = false;
          }else{
            this.fleafinished = true
            this.fleaLoad=false
          }
        }
      })
    },
    //悬赏帮助列表
    getcommunityList(){
    let url = this.$api.Bounty + '?pageSize=5&page=' + this.page + '&is_solve=' + this.is_solve +'&category_id=' + this.category_id + '&sort_type=' + this.sort_type + '&credit_low=' + this.credit_low + '&credit_high=' + this.credit_high
    this.$http.get(url, false).then(res => {
      if(res.data.success){
        if(res.data.data.length){
          this.communityList = this.communityList.concat(res.data.data),
            this.page = this.page + 1
            this.bountyfinished = false
            this.bountyloading = false;
        }else{
          this.bountyfinished = true
          this.load = false
        }
      }
    })
    },
    //社区分类
    CommunityCategory(){
      let url = this.$api.CommunityCategory 
      this.$http.get(url, false).then(res => {
        if(res.data.success){
            this.category = res.data.data
        }
      })
      let fleaurl = this.$api.CommunityCategory  + '?type=flea'
      this.$http.get(fleaurl,  false).then(res => {
        if(res.data.success){
            this.fleacategory = res.data.data
        }
      })
    },
    // 分类
    changeCategory(id,name){
      this.category_id = id ,
        this.category_name = name
    },
    changefleaCategory(id,name){
        this.fleaCategory_id = id,
        this.fleacategory_name = name
    
    },
    changeeleCategory(id,name){
      this.elecategory_id = id ,
        this.elecategory_name = name
    },
    //排序
    changeSorttype(type){
        this.sort_type = type
    },
    //是否解决
    changeSolve(solve){
        this.is_solve = solve
    },
    //是否包邮
    changeshipping(solve){
        this.is_free_shipping = solve
    },
    //悬赏积分
    changeCredit(low,high){
        this.credit_low = low,
        this.credit_high = high
    },
    //跳蚤悬赏积分
    changefleaCredit(low,high){
        this.fcredit_low = low,
        this.fcredit_high = high
    },
    //电子资源积分
    changeeleCredit(low,high){
        this.ecredit_low = low,
        this.ecredit_high = high
    },
    //重置
    reset(){
        this.is_solve = 'all',
        this.category_id = 0,
        this.sort_type = 'time',
        this.credit_low = '',
        this.credit_high = '',
        this.page = 1
    },
    //跳蚤重置
    freset(){
        this.fleaCategory_id = 0,
        this.fcredit_low = '',
        this.fcredit_high = '',
        this.fleapage = 1,
        this.is_free_shipping = 'all'
    },
    //电子重置
    ereset(){
      this.elecategory_id = '',
        this.ecredit_low = '',
        this.ecredit_high = '',
        this.elepage = 1
    },
    // 确认
    confirm(){
        this.page = 1,
        this.communityList = [],
        this.category_name_show = this.category_name,
        this.show = false
      this.getcommunityList()
    },
     // 跳蚤确认
     fconfirm(){
        this.fleapage = 1,
        this.fleaList = [],
        this.fleacategory_name_show = this.fleacategory_name,
        this.fleashow = false
      this.getfleaList()
    },
    // 电子确认
    econfirm(){
      this.elepage = 1,
        this.eleList = [],
        this.elecategory_name_show = this.elecategory_name,
        this.eleshow = false
      this.geteleList()
    },
    showPopup() {
      this.show = true 
    },
    fshowPopup() {
      this.fleashow = true 
    },
    eshowPopup() {
      this.eleshow = true
    },
    onClose() {
      this.show = false 
    },
    fonClose() {
      this.fleashow = false
    },
    eonClose() {
      this.eleshow = false
    },
    onChange(name,title) {
      this.active = name
      },
     
      //跳转搜索
      goSearch(){
          this.$router.push({path: '/community/search',query: {act: this.active}})
      },
      
      //发布
      create(){
        if(!this.$cache.getIsLogin()) {
            this.$store.commit('M_LOGIN_POP', true)
          }else{
if(this.active == 0){
            this.$router.push({path: '/community/create'})
       
        }
        if(this.active == 1){
            this.$router.push({path: '/community/market-create'})
        }
          if(this.active == 2){
            if (this.userInfo.level >= 3){
              this.$router.push({path: '/community/resources-create'})
            }else {
              this.$toast('用户等级需3级及以上');
            }
          }
          }
        
       
      },
      listupdata(){
          this.page = 1,
      this.communityList = [],
      this.load = true,
      this.fleaList = [],
      this.fleaLoad = true,
      this.fleapage = 1
        this.eleList = [],
          this.eleLoad = true,
          this.elepage = 1
        this.getcommunityList()
        this.CommunityCategory()
        this.getfleaList()
        this.geteleList()
      },
      bountyonLoad(){
        if(this.load){
          this.getcommunityList()
        }
      },
      fleaonLoad(){
        if(this.fleaLoad){
          this.getfleaList()
        }
      },
    eleonLoad(){
      if(this.eleLoad){
        this.geteleList()
      }
    },

  },
  components:{
    UserInfo,
    bounty,
    flea,
    resources,
    NoData
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/community/index";

</style>
<style lang="scss">
$r: 750/16/1rem;
.community{
.van-tab{
      flex: unset;
    }
    .van-tabs__nav{
      width: 80%;
      padding: 0 10%;
    }
    .van-tab {
      width: 200/$r;
      font-size: 28/$r;
      font-weight: 400;
      color: #93999F;
    }
.van-tab--active{
   width: 200/$r;
      font-size: 36/$r;
      font-weight: 500;
      color: #1C1F21;
}
  
    .van-tabs__line {
      width: 40/$r !important;
      height: 6/$r;
      background: #FF6B29;
      border-radius: 3/$r;
      bottom: 3px;
    }
  
    .tabs {
      position: relative;
    }
  .van-sticky--fixed{
    transform: unset!important;
  }
}

  
</style>